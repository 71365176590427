import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClientService } from './http-client.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(private httpService: HttpClientService, public http: HttpClient) {}
    getAllReportList(reuqest) {
        return this.httpService.post('reports', reuqest).pipe(
            catchError(error => throwError(error)),
            map((res: any) => {
                return res;
            })
        );
    }
    searchData() {
        return this.http.get('search');
    }
    deleteReport(param: any) {
        return this.http.post('delete',param);
    }
    downloadReport(param: any) {
        return this.http.post('download', param);
    }
    searchField(param: any) {
        return this.http.post('search_field', param);
    }
    advanceSearchField(param: any) {
        return this.http.post('advance_search_field', param);
    }
    getAllUserList(reuqest) {
        return this.httpService.post('users', reuqest).pipe(
            catchError(error => throwError(error)),
            map((res: any) => {
                return res;
            })
        );
    }
    loginNow(param: any) {
        return this.http.post('login_now',param);
    }
}