import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FacadeService } from '../services/facade.service';
@Injectable({
  providedIn:'root'
})
export class SkipLoginGuard implements CanActivate {
  constructor(private router: Router,private service: FacadeService) {}
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    if (this.service.isAuthenticated()) {
      //redirect for forgot password
      if (state.root.queryParams && state.root.queryParams.code) {
        localStorage.clear();
        return true;
      } else {
        // redirect if already logged in
        let type=this.service.auth().userType;
        this.router.navigate(['/my-profile']);
      }
      return false;
    } else {
      return true;
    }
  }
}