import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(public http: HttpClient,private _router: Router) { }
  clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
  login(param: any) {
    return this.http.post('login', param);
  }
  verifyOtp(param: any) {
    return this.http.post('verify_otp', param);
  }
  forgot(param: any) {
    return this.http.post('forgot_password', param);
  }
  reset_password(param: any) {
    return this.http.post('reset_password', param);
  }
  get_user_detail() {
    return this.http.get('user_details');
  }
  update_user_info(param:any) {
    return this.http.post('change_profile', param);
  }
  update_password(param:any) {
    return this.http.post('change_password', param);
  }
  authorize(auth: any): void { 
    localStorage.setItem('auth', JSON.stringify(auth));    
  }
  isAuthenticated(): boolean {
    return this.auth() ? (this.auth().token ? true : false) : false;
  }
  auth() {
    return JSON.parse(localStorage.getItem('auth'));
  }
  logout() {
    if (this.auth() && this.auth().token) {
      this.clear();
    }
    this._router.navigate(['/login']);
  }
}