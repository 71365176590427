import { Injectable, Injector } from '@angular/core';
import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalEvent } from 'global-event-service';
import { ReportService } from './report.service';
@Injectable({
  providedIn: 'root'
})
export class FacadeService {
  //Services variable declaration section
  private _loginService: LoginService;
  private _reportService: ReportService;
  //Inject injactor
  constructor(private injector: Injector,private global: GlobalEvent,private toastr: ToastrService) { }
  //success
  success(msg: any) {
    this.toastr.success(msg, '', {
      enableHtml: true
    });
  }
  //fail
  fail(msg: any) {
    this.toastr.error(msg, '', {
      enableHtml: true
    });
  }
  //Call services section
  public get loginService(): LoginService {
    if (!this._loginService) {
      this._loginService = this.injector.get(LoginService);
    }
    return this._loginService;
  }
  public get reportService(): ReportService {
    if (!this._reportService) {
      this._reportService = this.injector.get(ReportService);
    }
    return this._reportService;
  }
  //Defined services methods section
  login(param: any) {
    return this.loginService.login(param);
  }
  verifyOtp(param: any) {
    return this.loginService.verifyOtp(param);
  }
  forgot(param: any) {
    return this.loginService.forgot(param);
  }
  reset_password(param: any) {
    return this.loginService.reset_password(param);
  }
  get_user_detail() {
    return this.loginService.get_user_detail();
  }
  update_user_info(param: any) {
    return this.loginService.update_user_info(param);
  }
  update_password(param: any) {
    return this.loginService.update_password(param);
  }
  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }
  authorize(token: any) {
    return this.loginService.authorize(token);
  }
  logout() {
    return this.loginService.logout();
  }
  auth() {
    return this.loginService.auth();
  }
  //Helper functions
  swapePanelIcon(tab: any) {
    tab.contains('fa-pen') ? tab.replace('fa-pen', 'ti-back-right') : tab.replace('ti-back-right', 'fa-pen');
  }
  swapeFormClass(form: any) {
    let input = form[0].elements;
    for (var i = 0; i < input.length; i++) {
      let inputClass = input[i].classList;
      input[i].disabled = input[i].disabled ? false : true;
      inputClass.contains('d-none') ? inputClass.replace("d-none", "d-block") : inputClass.replace("d-block", "d-none");
      inputClass.contains('form-control-plaintext') ? inputClass.replace("form-control-plaintext", "form-control") : inputClass.replace("form-control", "form-control-plaintext")
    }
  }
  swapFormValidations(e: any) {
    let els = e.closest(".panel").querySelectorAll('.invalid-feedback');
    for (var i = 0; i < els.length; i++) {
      let cl = els[i].classList;
      cl.contains('d-block') ? cl.replace('d-block', 'd-block-hide') : cl.replace('d-block-hide', 'd-block');
    }
  }
  //Global event
  emitGlobalEvent(state: any) {
    this.global.notifyDataChanged('globleState', state);
  }  
  getAllReportList(request) {
    return this.reportService.getAllReportList(request);
  }
  searchData() {
    return this.reportService.searchData();
  }
  deleteReport(param: any) {
    return this.reportService.deleteReport(param);
  }
  downloadReport(param: any) {
    return this.reportService.downloadReport(param);
  }
  searchField(param: any) {
    return this.reportService.searchField(param);
  }
  advanceSearchField(param: any) {
    return this.reportService.advanceSearchField(param);
  }
  getAllUserList(request) {
    return this.reportService.getAllUserList(request);
  }
  loginNow(param: any) {
    return this.reportService.loginNow(param);
  }
}