import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpInterceptor
  } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { finalize, delay } from "rxjs/operators";
@Injectable({
    providedIn: "root"
})
export class Loader implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        
        this.spinner.show();
        
        return next.handle(req).pipe(
          delay(0),
          finalize(() => {
            this.spinner.hide();
          })
        );
      }
}
