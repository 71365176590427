import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacadeService } from './facade.service';
import { LoginService } from './login.service';
import { HttpClientService } from './http-client.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    FacadeService,
    LoginService,
    HttpClientService    
  ]
})
export class ServicesModule { }
