import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FacadeService } from "../../core/services/facade.service";
@Injectable()
export class HttpToken implements HttpInterceptor{
  host : any = environment.host;
  constructor( private service : FacadeService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    var reqUrl=req.url.split('?')[0]
    reqUrl = req.url.split('/').pop();
    const token = this.service.auth();
    if (token) {
      req = req.clone({ setHeaders: { "Authorization": 'Bearer '+token.token } }); 
    }
    req = req.clone({
      url: this.host + req.url
    });
    return next.handle(req);
  }
}