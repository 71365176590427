import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServicesModule } from './services/services.module';
import { HttpToken } from './interceptors/http-token';
import { Loader } from './interceptors/loader';
import { exception } from './interceptors/exception';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientService } from './services/http-client.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ServicesModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpToken, 
      multi: true 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Loader,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: exception,
      multi: true
    },
    HttpClientService
  ],
})
export class CoreModule { }
