import { Component, ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'my-app';
  constructor(
    private cdref: ChangeDetectorRef) {
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
}
