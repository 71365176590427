import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SkipLoginGuard } from "../app/core/guards/skip-login.guard";
import { NotFoundComponent } from './not-found/not-found.component';
import { BlankLayoutComponent } from './non-auth/blank-layout/blank-layout.component';
import { AuthGuardService } from './core/guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [SkipLoginGuard],
    component: BlankLayoutComponent,
    loadChildren: './non-auth/non-auth.module#NonAuthModule'
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    component: NotFoundComponent,
    path: '404'
  },
  {
    path: '**',
    redirectTo: '404'
  },
];
export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes);