import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-empty-route',
  template:'<div></div>'
})
export class EmptyRouteComponent implements OnInit {
  constructor(private route: ActivatedRoute,private router: Router) {}
  ngOnInit() {
    this.router.navigate(['/login']);
  }
}