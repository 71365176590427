import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FacadeService } from '../services/facade.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  routerEvents: any;
  constructor(
    private _router: Router,
    private service: FacadeService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.service.isAuthenticated()) {
      return true;
    } else {
      this._router.navigateByUrl('/login');
      return false;
    }
  }
}