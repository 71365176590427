import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, retry, debounceTime } from 'rxjs/operators';
import { FacadeService } from "../services/facade.service";
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class exception implements HttpInterceptor {
  constructor(
    private service:FacadeService,
    private translate : TranslateService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(request).pipe(
      tap(
        event => { },
        error => {
          if (error instanceof HttpErrorResponse) {
            this.code(error.status,error);
            //this.toastr.error(error.message, error.name, { closeButton: true });
          }
        }
      ));
  }
  code(status:any,msg:any) 
  {
    switch (status) {
      case 0:
        this.service.fail(this.translate.instant('500ErrorMsg'));
        break;
      case 500:
        this.service.fail(this.translate.instant('500ErrorMsg'));
        //this.service.fail(msg.statusText, "500");
        break;
      case 400:
        this.service.fail(msg.message);
        break;
      case 401:
        this.service.fail(this.translate.instant('Unauthorized'));
        this.service.logout();
        break;
      case 403:
        this.service.fail(msg.message);
        break;    
      case 404:
        this.service.fail(msg.message);
        break;
        case 409:
          this.service.fail(msg.message);
          break;  
      case 422:{
        if(msg.error && msg.error.errors){
          let errorsArray = [];
          errorsArray = Object.values(msg.error.errors);
          if(errorsArray.length > 0){
            for(let i = 0; i < errorsArray.length; i++){
              this.service.fail(errorsArray[i]);
            }
          }
        }
        if(msg.error && msg.message){
          this.service.fail(msg.message);
        }
        break;
      }
      case 204:
        this.service.fail("No Content");
        location.reload();
        break;    
      case -1: // request time out
      this.service.fail("request time out");
        break;
      default:
        console.log(msg);
    }
  }
}
